.webinars_page_wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .webinars_filters_wrapper {
        display: flex;
        padding: 0px 24px;
        gap: 16px;
        overflow-x: scroll;

        .filter_tag_wrapper {
            border-radius: 8px;
            border: 1px solid var(--gs-learn-mockingbird, #030A21);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 10px;
        }

    }

    .webinars_body_wrapper {
        padding: 24px;
        gap: 20px;
    }
}

@media screen and (max-width: 800px) {
    .webinars_page_wrapper {}

}