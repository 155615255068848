.status_card_wrapper_desktop {
    border-radius: 20px;
    border: 1px solid var(--gs-learn-light-mode-grey-2, rgba(3, 10, 33, 0.30));
    background: var(--gs-learn-surface, #FFF);

    .status_card {
        gap: 12px;
        padding: 24px;
        display: grid;
        grid-template-columns: 0fr 1fr;

        .session_wrapper {
            .broadcasting {
                border-radius: 32px;
                background: #F5F5F5;
                padding: 10px 16px 10px 10px;
                align-items: center;
                gap: 4px;
                max-width: 144px;
            }
        }

        .icon_wrapper {
            height: 40px;
            width: 40px;
            background-color: yellow;
        }

        .details_wrapper {
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            align-items: center;

            .details {
                gap: 12px;
                align-items: flex-start;
                justify-content: inherit;
            }
        }
    }

    .status_card_reminder {
        justify-content: space-between;
        border-top: 1px solid var(--gs-learn-light-mode-grey-4, rgba(3, 10, 33, 0.10));
        padding: 24px;

        .left_content {
            align-items: center;
            gap: 6px;

            span {
                margin-top: 4px;
            }
        }

        .button_content {
            .button_flex {
                border-radius: 12px !important;

                span {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    min-width: 194px;
                    justify-content: center;
                    align-items: center;
                    color: #FFFFFF;
                }
            }

            .black_text {
                span {
                    color: black !important;
                    min-width: unset !important;
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.status_card_wrapper_mobile {
    display: none;
}

@media screen and (max-width: 800px) {
    .status_card_wrapper_desktop {
        display: none;
    }

    .status_card_wrapper_mobile {
        display: block;
        border-radius: 20px;
        border: 1px solid var(--gs-learn-light-mode-grey-2, rgba(3, 10, 33, 0.30));
        background: var(--gs-learn-surface, #FFF);
        border-radius: 12px;

        .broadcasting_wrapper {
            padding: 20px 0px 0px 20px;
            .broadcasting {
                border-radius: 32px;
                background: #F5F5F5;
                padding: 10px 16px 10px 10px;
                align-items: center;
                gap: 4px;
                max-width: 144px;
            }
        }

        .status_card {
            padding: 20px;
            grid-template-columns: 0fr 1fr;
            column-gap: 12px;
            row-gap: 16px;

            .left_content {
                gap: 8px;
            }
        }

        .status_card_reminder {
            border-top: 0.5px solid var(--gs-learn-light-mode-grey-4, rgba(3, 10, 33, 0.10));
            padding: 20px 16px;
            gap: 16px;

            .session_wrapper {

                // max-height: 32px;
                span {
                    font-size: 11px;
                }
            }

            .button_content {
                .button_flex {
                    border-radius: 12px !important;

                    span {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        min-width: 194px;
                        justify-content: center;
                        align-items: center;
                        color: #FFFFFF;
                    }
                }

                .black_text {
                    span {
                        color: black !important;
                        min-width: unset !important;
                        display: flex;
                        gap: 4px;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .fourteen_font {
            font-size: 14px !important;
        }
    }
}